.product {
  &__back {
    -webkit-tap-highlight-color: transparent;
    width: fit-content;
    color: #2d2d2d;
    padding-top: 170px;
    padding-left: 20px;
    @media screen and (max-width: 768px) {
      // padding: 75px 0px 10px 5px;
      padding: 95px 0px 10px 5px;
    }
    display: flex;
    align-items: center;
    gap: 5px;
    &__label {
      font-family: sans-serif;
      font-size: 0.75rem;
      line-height: 0.75rem;
      font-weight: 500;
      letter-spacing: 0.15em;
      text-transform: uppercase;
    }
    &__icon {
      font-size: 10px;
      padding-right: 5px;
    }
  }
  &__wrapper {
    width: 100%;
    @media screen and (min-width: 768px) {
      padding: 20px;
    }
    display: flex;
    justify-content: space-between;
    gap: 20px;
    box-sizing: border-box;
    @media screen and (max-width: 768px) {
      padding: 10px 0px;
      // padding-top: 80px;
      flex-direction: column;
      gap: 20px;
    }
    &__left {
      flex: 1;
      @media screen and (max-width: 768px) {
        min-height: 532px !important;
        // background-color: red;
        height: 100%;
        width: 100%;
        display: flex;
      }
      &__img {
        width: 100%;
        object-fit: cover;
        flex: 1;
        border-radius: 5px;
        @media screen and (min-width: 769px) {
          height: 100%;
          max-height: 650px;
        }
      }
      &__grid {
        display: none;
        @media screen and (min-width: 768px) {
          display: flex;
          gap: 5px;
          position: relative;
          flex-direction: column;
          overflow: scroll;
          max-height: 440px;
          &__img {
            max-width: 100px !important;
            min-height: 100px !important;
            // height: 300px;
            border-radius: 5px;
            object-fit: cover;
          }
        }
        @media screen and (max-width: 767px) {
          display: flex;
          gap: 5px;
          position: relative;
          flex-direction: row;
          overflow: scroll;
          max-height: 440px;
          &__img {
            max-width: 100px !important;
            min-height: 100px !important;
            // height: 300px;
            border-radius: 5px;
            object-fit: cover;
          }
        }
      }
      @media screen and (min-width: 768px) {
        display: flex;
        gap: 10px;
      }
      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column-reverse;
        gap: 10px;
        padding-bottom: 8px;
      }
    }
    &__right {
      padding: 0px 10px;
      flex: 1;
      &__title {
        font-family: "Roboto";
        font-weight: 900;
        font-size: 24px;
        margin-top: 0px;
        text-transform: capitalize;
        // display: block;
        // color: #fff;
        // margin-bottom: 30px;
        // font-size: 1.875rem;
        // line-height: 2rem;
        letter-spacing: 0.15em;
        // margin-top: 15px;
        font-weight: 500;
        text-transform: uppercase;
        // text-align: center;
        font-family: sans-serif;
      }
      &__price {
        color: #333;
        font-family: sans-serif;
        font-weight: 400;
        font-size: 20px;
        span {
          font-family: sans-serif;
          font-weight: 400;
          font-size: 13px;
        }
      }
      &__attr {
        &__title {
          font-family: sans-serif;
          color: #000;
          font-weight: 400;
          font-size: 14px;
          margin-bottom: 8px;
        }
        &__wrapper {
          display: flex;
          align-items: center;
          gap: 5px;
          flex-wrap: wrap;
          &__item {
            cursor: pointer;
            &.color {
              width: 25px;
              height: 25px;
              border: none;
              position: relative;
              margin-right: 8px;
              background-size: contain;
              border-radius: 50%;
              border: solid 0.0625rem #c5c5c5;
              &.active {
                border: 2px solid #000;
              }
            }
            &.size {
              position: relative;
              margin-right: 8px;
              margin-bottom: 8px;
              min-width: 38px;
              text-align: center;
              line-height: 36px;
              border: solid 1px #dddcdb;
              padding: 0px 4px;
              font-weight: 500;
              font-family: sans-serif;
              &.active {
                background: black;
                color: white;
                transition: 0.4s all ease;
              }
            }
          }
        }
      }
      &__btnParent {
        &__btn {
          cursor: pointer;
          text-transform: uppercase;
          color: #fff;
          background-color: #000;
          font-weight: 400;
          font-family: sans-serif;
          padding: 0 60px;

          font-size: 0.75rem;
          font-weight: 500;
          letter-spacing: 0.15em;
          line-height: 40px;
          text-transform: uppercase;
          transition: 0.3s all ease-in-out;
          width: fit-content;
          margin-top: 15px;
          @media screen and (max-width: 768px) {
            // padding: 0 40px;
            box-sizing: border-box;
            text-align: center;
            width: 100%;
          }
        }
      }
      &__description {
        padding-top: 20px;
        &__title {
          padding: 0px;
          margin: 9px 0px;
          font-family: sans-serif;
          color: #000;
          font-weight: 500;
          font-size: 14px;
        }
        &__paragraph {
          font-family: sans-serif;
          // font-weight:
          color: #5d5d5d;
          font-size: 14px;
          margin: 0px;
          padding: 0px;
        }
      }
    }
  }
  &__2nd {
    padding-top: 50px;
  }
}
.fullLoader {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #eee;
  height: 100%;
  z-index: 99999999;
}
