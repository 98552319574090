.itemContent {
  &__wrapper {
    width: 100%;
    &__subtitle {
      padding-top: 10px;
    }
    &__title {
      @media screen and (max-width: 788px) {
        font-size: 23px;
      }
    }
    &__paragraph {
      text-align: center;
      font-family: sans-serif;
      color: #eee;
      font-size: 14px;
      padding: 5px;
      &.withImg {
        @media screen and (min-width: 768px) {
          max-width: 600px;
          margin: auto;
          line-height: 24px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
