.hero {
  &__content {
    position: absolute;
    z-index: 9999;
    top: 50%;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -0%);
    &__subtitle {
      display: block;
      color: #fff;
      font-size: 0.9rem;
      line-height: 2rem;
      letter-spacing: 0.15em;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      font-family: sans-serif;
      margin-bottom: 0px;
    }
    &__title {
      display: block;
      color: #fff;
      margin-bottom: 30px;
      font-size: 1.875rem;
      line-height: 2rem;
      letter-spacing: 0.15em;
      margin-top: 15px;
      font-weight: 400;
      text-transform: uppercase;
      text-align: center;
      font-family: sans-serif;
      max-width: 600px;
      margin: auto;
      margin-top: 25px;
      margin-bottom: 25px;
      @media screen and (max-width: 768px) {
        font-size: 1.575rem;
      }
    }
    &__btn {
      cursor: pointer;
      text-transform: uppercase;
      color: #2d2d2d;
      background-color: #ededed;
      font-weight: 400;
      font-family: sans-serif;
      width: 100%;
      // display: none;
      display: block;
      padding: 0 60px;
      @media screen and (max-width: 768px) {
        padding: 0 40px;
        width: fit-content !important;
        white-space: nowrap !important;
      }

      font-size: 0.75rem;
      font-weight: 500;
      letter-spacing: 0.15em;
      line-height: 40px;
      text-transform: uppercase;
      transition: 0.3s all ease-in-out;
      width: fit-content;
      margin: auto;
    }
  }
  &__loading {
    height: 100vh;
    width: 100%;
    background: #000;
    position: absolute;
    z-index: 999900;
    display: flex;
    align-items: center;
    justify-content: center;
    &__animation {
      height: 10px;
      width: 100px;
      background: #777676;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        background: #fff;
        width: 100%;
        height: 100%;
        animation: videoLoader 0.4s ease-in-out infinite;
      }
    }
  }
}

video {
  width: 100%;
  height: 100%;
  z-index: 1 !important;
  object-fit: cover;
  position: relative;
  object-position: unset;
}
.mySwiper {
  width: 100%;
}
.swiper {
  width: 100%;
  height: 100vh;
  @media screen and (max-width: 768px) {
    height: 100vh;
  }
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  // background: red;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet-active {
  background-color: transparent !important;
  border: 1px solid #f8f8f7;
}
.swiper-button-prev,
.swiper-button-next {
  color: #f8f8f7;
  margin: 1px 35px;
  margin-top: -20px;
}
@media only screen and (max-width: 767px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}

.heroImage {
  padding: 20px 0px;
  width: 100%;
  min-height: 290px;
  background-image: url("https://cdn.sanity.io/images/jzh2d17g/production/aa1da372c222105f99c97133812e2fbbb075bd30-984x1280.jpg");
  background-position: center;
  background-size: cover;
  display: flex;
  position: relative;
}

@keyframes videoLoader {
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
}
