.notFound {
  &__wrapper {
    padding-top: 150px;
    @media screen and (max-width: 768px) {
      padding: 75px 0px 10px 5px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    &__label {
      font-family: sans-serif;
      font-weight: 500;
      font-size: 30px;
    }
    &__icon {
      font-size: 40px;
    }
  }
}
