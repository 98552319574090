.mySwiper--home {
  //   overflow: hidden;
  height: 400px;
  width: 300px;
  margin: auto;
  padding: 0px 0px 40px 0px;
  @media screen and (min-width: 768px) {
    display: none;
  }
  @media screen and (max-width: 370px) {
    height: 350px;
    width: 250px;
  }
  .swiper-slide {
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    border-radius: 18px;
    object-fit: cover;
  }
  .swiper-slide-shadow {
    display: none;
  }
}
.home {
  &__featured {
    &--title {
      padding-top: 20px;
    }
  }
}
