.card {
  // height: 345px;
  font-family: sans-serif !important;
  // margin-bottom: 10px;
  // width: 150px;
  position: relative;
  &__wrapper {
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 30px 30px 0px 0px;

    &__title {
      font-family: sans-serif !important;
      margin: 0px;
      margin-bottom: 5px;
      font-size: 0.875rem;
      color: #2d2d2d;
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1.33;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      text-align: left;
      padding-top: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    &__price {
      font-family: sans-serif !important;
      color: #000;
      font-size: 10px;
      font-weight: 400;
      line-height: 1.33;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      text-align: left;
      margin: 0px;
    }
  }
}
