.productsSlider {
  // padding: 10px 0px;
  padding-bottom: 40px;
  height: 100%;
  &__wrapper {
    height: 100%;
    &__item {
      border-radius: 30px 30px 0px 0px;
      overflow: hidden;
      &--last {
        height: auto;
        &__link {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          cursor: pointer;
          &__label {
            margin: 0px;
            font-weight: 400;
            font-family: sans-serif;
            font-size: 15px;
          }
          &__icon {
            font-size: 11px !important;
          }
        }
      }
    }
  }
}
