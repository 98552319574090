.header {
  position: absolute;
  width: 100%;
  transition: 0.35s linear 0.1s;
  box-sizing: border-box;
  padding: 0px 15px;
  @media screen and (min-width: 768px) {
    padding: 0px 30px;
  }
  // box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999999;
  &__wrapper {
    &:after {
      // position: absolute;
      // top: 0;
      // left: 0;
      // width: 100%;
      // content: "";
      // z-index: 99999;
      // box-shadow: 1px -2px 75px 50px rgba(0, 0, 0, 0.75); // background-color: red;
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: block;
      width: 100%;
      height: 100%;
      // min-height: 150px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.675) 0%,
        rgba(0, 0, 0, 0) 97.86%
      );
      transition: 0.35s linear 0.1s;
      opacity: 1;
    }
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__left {
      a {
        color: white;
      }
    }
    &__mid {
      img {
        height: 150px;
        @media screen and (max-width: 768px) {
          height: 80px;
        }
      }
      &__title {
        text-transform: uppercase;
        font-size: 35px;
        letter-spacing: 1.8px;
        color: white;
        font-family: "Roboto", sans-serif;
        // font-family: sans-serif;
        font-weight: 900;

        @media screen and (max-width: 768px) {
          font-size: 27px;
          font-weight: 600;
        }
      }
    }
    &__right {
      display: flex;
      align-items: center;
      gap: 5px;
      &__icon {
        color: white;
        font-size: 18px;
        cursor: pointer;
      }
    }
    // &--scroll {
    //   background: white;
    // }
  }
  &__lowerHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    &__wrapper {
      &__list {
        list-style: none;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        &__item {
          color: white;
          cursor: pointer;
          font-size: 1.2rem;
          line-height: 1rem;
          letter-spacing: 0.15em;
          text-transform: uppercase;
          color: #f8f8f7;
          font-family: "Roboto", sans-serif;
          // font-family: sans-serif;
          font-weight: 500;
          font-size: 0.7rem;
          line-height: 1rem;
          letter-spacing: 0.15em;
          text-transform: uppercase;
          color: #f8f8f7;
          transition: 0.3s ease;
          //
        }
      }
    }
    @media screen and (max-width: 768px) {
      // background-color: red;
      display: none;
      // position: absolute;
      // z-index: 999;
      // height: 100vh;
      // top: 0;
      // left: 0;
      // box-sizing: border-box;
      // padding: 50px 30px;
      // &__wrapper {
      //   width: 100%;
      //   height: 100%;
      //   &__list {
      //     padding: 0px;
      //     list-style: none;
      //     width: 100%;
      //     display: flex;
      //     align-items: unset;
      //     height: 100%;
      //     flex-direction: column;
      //     gap: 20px;
      //     &__item {
      //       padding-bottom: 20px;
      //       color: white;
      //       cursor: pointer;
      //     }
      //   }
      // }
    }
  }
  &--scroll {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    .header__wrapper__left a {
      color: #000;
    }
    .header__lowerHeader {
      display: none;
    }
    .header__wrapper__mid__title,
    .header__wrapper__right__icon {
      color: #000;
    }
    .header__wrapper::after {
      opacity: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .hideOnMobile {
    display: none;
  }
}
@media screen and (min-width: 769px) {
  .hideOnComputers {
    display: none;
  }
}

.header2 {
  background-color: white;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  i,
  a {
    color: black !important;
  }
  .header {
    &__wrapper {
      &::after {
        background: none;
      }
    }
  }
}
