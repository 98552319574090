.footer {
  background-color: #f3f3f1;
  color: #2d2d2d;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 0.15em;
  padding: 48px 40px;
  margin-top: 50px;
  text-transform: uppercase;
  &__wrapper {
    max-width: 1200px;
    margin: auto;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: unset;
    }
    &__col {
      flex: 1;
    }
  }
  &__newsletter {
    max-width: 1200px;
    margin: auto;
    &__inptWrapper {
      position: relative;
      width: 100%;
      &__inpt {
        order: 2;
        display: block;
        width: 100%;
        padding-right: 26px;
        padding-bottom: 8px;
        // font-family: Helvetica Neue LT W05;
        font-family: sans-serif;
        font-weight: 700;
        letter-spacing: 0.03em;
        font-size: 0.75rem;
        line-height: 1rem;
        color: #2d2d2d;
        outline: none;
        box-shadow: none;
        border: none;
        border-bottom-color: currentcolor;
        border-bottom-style: none;
        border-bottom-width: medium;
        border-bottom: 1px solid #2d2d2d;
        background: transparent;
        border-radius: 0;
        transition: 0.3s ease;
      }
      &__icon {
        position: absolute;
        right: 0px;
        top: 0px;
        font-size: 16px;
        color: #4d4d4d;
      }
    }
    &__paragraph {
      opacity: 1;
      visibility: visible;
      color: #4d4d4d;
      // font-family: Helvetica Neue LT W05;
      font-family: sans-serif;
      font-weight: 400;
      font-size: 0.6875rem;
      line-height: 0.875rem;
      letter-spacing: 0.03em;
      transition: 0.3s ease;
    }
  }
  &__bottom {
    max-width: 1200px;
    margin: auto;
    padding: 30px 0;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #c5c5c5;
    @media screen and (max-width: 768px) {
      border-top: none;
      flex-direction: column;
      align-items: start;
      gap: 30px;
    }
    &__links {
      display: flex;
      gap: 20px;
      align-items: center;
      &__link {
        font-family: "Roboto";
        font-weight: 500;
        cursor: pointer;
      }
      @media screen and (max-width: 768px) {
        flex-direction: column;
        &__link {
          margin: 8px 0px;
        }
      }
    }
    &__icons {
      gap: 20px;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: end;
      @media screen and (max-width: 768px) {
        padding-top: 30px;
        border-top: 1px solid #c5c5c5;
        border-width: 100%;
      }
      &__icon {
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
}
