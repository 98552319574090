.imageWithContent {
  padding-bottom: 40px;
  width: 100%;

  &__wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      min-height: 500px;
    }

    @media screen and (min-width: 768px) {
      max-height: 900px;
      width: 100%;
      margin: auto;
    }

    .imageWithContent__parallax {
      height: 600px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.5);

      // Additional styling for the parallax container
      // Add any custom styles or animations here
    }
  }
  &__btn {
    background: #eee;
    color: #000;
  }
}

.xxxx {
  background-size: cover;
}
