.item {
  /* border-bottom: 1px solid #cccccc65; */
}

.itemBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 15px 5px;
  font-size: 1rem;
  text-align: left;
  color: #007bff;
  background-color: transparent;
  border: none;
}

.itemBtn:hover {
  background-color: #f3f3f3;
}

.itemBtnExpanded {
  background-color: #e7e7e7;
}

.itemContent {
  transition: height 0.25s cubic-bezier(0, 0, 0, 1);
}

.itemPanel {
  padding: 1rem;
}

.chevron {
  /* margin-left: auto; */
  transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
  font-size: 12px;
}

.itemBtnExpanded .chevron {
  transform: rotate(180deg);
}

.faq {
}

.faq--btn {
  color: #000;
  font-weight: 500 !important;
  font-family: sans-serif !important;
}
.szh-accordion__item-panel {
  font-weight: 400;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
