.search {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: -100%;
  height: 100vh;
  width: 100%;
  z-index: 9999999;
  background-color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  padding: 30px 15px;
  &--show {
    left: 0 !important;
  }
  &__wrapper {
    position: relative;
    width: 100%;
    &__close {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      font-size: 13px;
      color: #666;
    }
    &__inputWrapper {
      padding-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 95%;
      @media screen and (min-width: 768px) {
        width: 70%;
      }
      margin: auto;
      border-bottom: 1px solid #000;
      padding-bottom: 10px;
      &__input {
        border: none;
        outline: none;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 19px;

        width: 100%;
        &::placeholder {
          color: #666;
          font-family: "Roboto";
          font-weight: 400;
          font-size: 19px;
        }
      }
      &__icon {
        color: #666;
        font-size: 18px;
        margin-left: 8px;
      }
      &__loader {
        width: 19px !important;
        height: 17px !important;
      }
    }
    &__list {
      padding-top: 10px;
      width: 95%;
      @media screen and (min-width: 768px) {
        width: 70%;
      }
      margin: auto;
      &__item {
        padding: 5px;
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
        &__img {
          margin-right: 20px;
          width: 90px;
          object-fit: cover;
          object-position: center;
          border-radius: 8px !important;
          // width: 100%;
        }
        &__name {
          font-family: sans-serif;
          font-weight: 400;
          font-size: 20px;
        }
        &__price {
          font-weight: 400;
          font-family: sans-serif;
          color: #5d5d5d;
        }
      }
    }
  }
}
