.mobileHeader {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: -100% !important;
  height: 100vh;
  width: 100%;
  z-index: 9999999;
  background-color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 30px 15px;
  transition: all 0.4s ease;
  &--show {
    left: 0 !important;
  }
  &__wrapper {
    position: relative;
    width: 100%;
    &__close {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      font-size: 13px;
      color: #666;
    }
    &__list {
      padding-top: 45px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;
      &__item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        &__label {
          font-size: 13px;
          line-height: 1rem;
          letter-spacing: 0.15em;
          font-family: sans-serif;
          font-weight: 500;
          text-transform: uppercase;
          color: #2d2d2d;
        }
      }
    }
  }
}

// element {

// }
// .menu__item--link {

//     font-family: Helvetica Neue LT W05;
//     font-weight: 500;
//     font-size: .75rem;
//     line-height: 1rem;
//     letter-spacing: .15em;
//     text-transform: uppercase;
//     color: #f8f8f7;

// }
