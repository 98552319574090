.faq {
  &__wrapper {
    padding: 150px 20px;
    @media screen and (max-width: 768px) {
      padding: 70px 20px;
    }
    max-width: 900px;
    margin: auto;
  }
}
