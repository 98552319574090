.gridProducts {
  padding: 20px 0px;
  &__wrapper {
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    &__item {
      margin-bottom: 10px;
      @media screen and (min-width: 540px) {
        width: 33.33333333%;
      }
      width: 50%;
    }
    &__btn {
      margin-top: 30px;
      display: block;
    }
  }
  margin-bottom: 40px;
}
