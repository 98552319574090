.collection {
  width: 100%;
  &__paragraph {
    max-width: 100%;
    padding: 30px 15px;
    &__label {
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-family: sans-serif;
      text-align: center;
      display: block;
      font-weight: 400;
      letter-spacing: 0.03em;
    }
  }
  &__wrapper {
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    &__item {
      margin-bottom: 10px;
      @media screen and (min-width: 540px) {
        width: 33.33333333%;
      }
      width: 50%;
    }
  }
  &__filterWrapper {
    position: relative;
    // HERE
    &__sortParent {
      position: absolute;
      z-index: 10001;
      padding: 5px;
      background-color: #fff;
      left: 125px;
      border-radius: 5px;
      box-shadow: 1px 1px 5px 6px rgba(0, 0, 0, 0.09);
      box-sizing: border-box;
      padding: 2px 10px;
      // transition: all 10s ease;
      &__item {
        padding: 4px;
        font-family: sans-serif;
        font-size: 14px;
        margin: 8px 0px;
        cursor: pointer;

        &:hover {
          background-color: #eee;
        }
      }
    }
    &__parent {
      font-size: 13px;
      padding: 0px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__left {
        font-family: sans-serif;
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 5px;
        &__icon {
          font-size: 16px;
          padding: 0px;
        }
      }
      &__right {
        font-family: sans-serif;
      }
    }
  }
}

.loader {
  text-align: center;
  display: flex;
  justify-content: center;
  width: 48px;
  height: 48px;
  border: 5px solid #000;
  border-bottom-color: #fff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader-count {
  width: 100px;
  height: 215px;
  display: block;
  margin: auto;
  position: relative;
  background: #fff;
  box-sizing: border-box;
  border-radius: 10px;
  &::after {
    content: "";
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    // top: 15px;
    // left: 15px;
    position: absolute;
    right: -1px;
    top: -7px;
    background-image: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.5) 10%,
        transparent 100%
      ),
      linear-gradient(#ddd 16px, transparent 0);
    background-repeat: no-repeat;
    background-size: 75px 175px, 100% 100px, 80% 16px, 80% 16px;
    background-position: -185px 0, center 10px, center 125px, center 155px;
    box-sizing: border-box;
    animation: animloader 1s linear infinite;
  }
}
.loader-skeleton {
  width: 100px;
  height: 915px;
  display: block;
  margin: auto;
  position: relative;
  background: #fff;
  box-sizing: border-box;
  border-radius: 10px;
  flex: 1;
  &::after {
    content: "";
    width: calc(100% - 30px);
    position: absolute;
    right: -1px;
    top: -7px;
    background-image: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.5) 10%,
        transparent 100%
      ),
      linear-gradient(#ddd 500px, transparent 0);
    background-repeat: no-repeat;
    background-size: 75px 175px, 100% 100px, 80% 16px, 80% 16px;
    background-position: -185px 0, center 10px, center 125px, center 155px;
    box-sizing: border-box;
    animation: animloader 1s linear infinite;
    min-height: 400px !important;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animloader {
  to {
    background-position: 185px 0, center 10px, center 125px, center 155px;
  }
}

.loader_test {
  width: 100%;
  display: flex;
  padding: 0px;
  flex-wrap: wrap;
  justify-content: space-between;
  &__card {
    margin-bottom: 20px;
    width: 47%;
    @media screen and (min-width: 768px) {
      width: 32.33333333%;
    }
    height: 300px;
    background-color: #f2f2f2;
    animation: loaderTestCard 1.5s ease-in-out infinite;
    &:nth-child(odd) {
      border-right: 5px solid #fff;
    }
  }
}

@keyframes loaderTestCard {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
