.productsCollection {
  padding-top: 30px;
  &__wrapper {
    overflow-x: hidden;
    box-sizing: border-box;
    // padding: 20px 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 0px;
    }
    &__left {
      width: 100%;
      flex: 1;
      &__btn {
        display: none;
        @media screen and (min-width: 768px) {
          display: block;
        }

        background-color: #000;
        color: white;
        padding: 0px 40px;
      }
      &__title {
        // padding: 0px !important;
        // margin: 0px !important;
        margin-bottom: 15px;
      }
    }
    &__right {
      overflow: hidden;
      flex: 1.2;
      padding: 20px;
      width: 100%;
      .swiper-button-next,
      .swiper-button-prev {
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        min-width: 45px;
        color: #000;
        height: 45px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.6);
        transition: 0.3s ease;
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      .swiper-button-next::after,
      .swiper-button-prev::after {
        font-size: 27px;
      }
      &__swiper {
        width: 100%;
        height: 100%;
        &__item {
          width: 100%;
          background-size: cover;
          background-position: center;
          height: 600px;
          width: 100%;
          @media screen and (max-width: 768px) {
            height: 425px;
          }
        }
      }
      &__btn {
        padding: 0px 40px;
        display: none;
        @media screen and (max-width: 768px) {
          margin-top: 40px;
          display: block;
        }
      }
    }
  }
}
.text-white {
  color: white;
}
.text-black {
  color: black;
}
.bg-white {
  background-color: white;
}
.bg-black {
  background-color: black;
}

// <div className="hero__contentx productsCollection__wrapper__left">
// <h3 className="hero__content__subtitle text-black">
//   Bridal Spring 2023
// </h3>
// <h3 className="hero__content__title text-black">
//   Spring Summer 2023
// </h3>
// <div className="hero__content__btn productsCollection__wrapper__left__btn">
//   Shop Now
// </div>
// </div>
