.contact {
  &__wrapper {
    padding: 150px 20px;
    @media screen and (max-width: 768px) {
      padding: 70px 20px;
    }
    max-width: 900px;
    margin: auto;

    &__title {
      font-weight: 400;
      font-family: sans-serif;
      text-align: center;
      margin: 0px 0px 10px 0px;
      font-size: 17px;
      border-bottom: 1px solid #ccc;
      padding-bottom: 5px;
      text-transform: capitalize;
    }
    padding-top: 140px;
    @media screen and (max-width: 768px) {
      padding-top: 70px;
    }
    &__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 20px;
      &__input {
        width: 100%;
        @media screen and (min-width: 768px) {
          width: 60%;
        }
        margin-bottom: 15px;
        padding: 11px;
        outline: none;
        &::placeholder {
          font-weight: 400;
          font-family: sans-serif;
        }
      }
      &__btn {
        background-color: #000;
        color: #fff;
      }
    }
  }
}
