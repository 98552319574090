.benefitsIcons {
  padding: 40px 0px;
  &__wrapper {
    max-width: 1200px;
    margin: auto;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 40px;
    }
    display: flex;
    justify-content: center;
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      &__icon {
        font-size: 35px;
      }
      &__subtitle {
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 1.375rem;
        letter-spacing: 0.15em;
        font-weight: 500;
        word-wrap: break-word;
        word-break: break-word;
        margin: 15px 0px;
        font-family: sans-serif;
        font-weight: 500;
      }
      &__paragraph {
        max-width: 250px;
        text-align: center;
        font-size: 0.75rem;
        line-height: 1rem;
        margin: 0px;
        font-family: sans-serif;
        font-weight: 400;
      }
    }
  }
  &__btn {
    margin-top: 20px;
    background: black;
    color: white;
  }
}
