.tinderCardWithTitle {
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
    padding: 50px 30px;
    &__wrapper {
      display: flex;
      align-items: center;
      // gap: 30px;
      &__left {
        flex: 1;
        width: 100%;
      }
      &__right {
        width: 100%;
        flex: 1;
      }
    }
    &__swiper {
      height: 500px;
      width: 400px;
      margin: auto;
      padding: 0px 0px 40px 0px;
      .swiper-slide {
        background-position: center;
        background-size: cover;
        height: 100%;
        width: 100%;
      }

      .swiper-slide img {
        display: block;
        width: 100%;
        border-radius: 18px;
        object-fit: cover;
      }
      .swiper-slide-shadow {
        display: none;
      }
    }
  }
}
