.about {
  &__wrapper {
    padding: 150px 20px;
    @media screen and (max-width: 768px) {
      padding: 70px 20px;
    }
    max-width: 900px;
    margin: auto;
    &__title {
      // text-align: left;
      font-weight: 400;
      font-family: sans-serif;
      text-align: center;
      margin: 0px 0px 10px 0px;
      font-size: 17px;
      border-bottom: 1px solid #ccc;
      padding-bottom: 5px;
      text-transform: capitalize;
    }
    &__paragraph {
      padding-top: 20px;
      margin: 0px;
      font-weight: 400;
      font-family: sans-serif;
      color: #555;
      font-size: 15px;
      line-height: 30px;
      text-align: center;
    }
  }
}
