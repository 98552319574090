.footerCol {
  &__wrapper {
    &__title {
      font-weight: 700;
      margin-bottom: 20px;
      pointer-events: none;
      line-height: 2;
      // letter-spacing: 0.15em;
      text-transform: uppercase;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 12px;
      color: #000;
    }
    &__links {
      &__link {
        color: #777;
        font-weight: 400;
        transition: 0.3s all ease-in-out;
        text-transform: initial;
        line-height: 2;
        font-family: "Roboto";
        font-weight: 500;
        cursor: pointer;
        letter-spacing: 0.1em;
      }
    }
  }
}
